import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  purchasedGalaDinner,
  purchasedWorkshop,
  physical,
  galaDinner,
  international,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedGalaDinner, setSelectedGalaDinner] = useState(null);

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedGalaDinner]}
        />

        <ProductCard
          cardTitle={
            "Asia Oceanian Conference of Physical and Rehabilitation Medicine 2025"
          }
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              // setSelectedGalaDinner(null);
            }
          }}
          handleRadio={() => {
            // setSelectedGalaDinner(null);
          }}
        />

        <h4 className="text-center text-navy mt-4">Select Gala Dinner</h4>
        {/* ------------ congress dinner CARD ------------ */}
        <ProductCard
          cardTitle={"Gala Dinner Ticket"}
          products={galaDinner}
          selectedState={selectedGalaDinner}
          setSelectedState={setSelectedGalaDinner}
          activated={!purchasedGalaDinner}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedGalaDinner(null);
            }
          }}
          handleRadio={() => {
            setSelectedGalaDinner(null);
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={
            purchasedPhysical
              ? purchasedPhysical && selectedGalaDinner === null
              : (selected === null && selectedGalaDinner === null) ||
                selected === null
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
